


































































































































import Vue from 'vue';
import { isMobile, loadExternalScript, processResponseError } from '@/common/misc';
import { LOGIN, REGISTER } from '@/store/actions';
import { checkRedirResTryLogin, firebaseGoogleLogin } from '@/common/firebase/firebase.client';
import { SET_AUTH } from '@/store/mutations';
import { API_URL, CAPTCHA_SITE_KEY, endpoints } from '@/common/config';
import { mapGetters } from 'vuex';
import jwtService from '@/common/jwt.service';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import '@/assets/css/login-registration.less';
import StorageService from '@/common/storage.service';
import ApiService from '@/common/api.service';
import { phoneNumberRegex } from '@/common/misc'

const messageDisplayDuration = 7500;

export default Vue.extend({
    name: 'loginRegisterView',
    data(){
        return{
            //FIXME: [LOW PRIOR] userData needs to be manually reset (see switchLoginRegister)
            userData: {
                username: '',
                email: '',
                password: '',
                confirmPassword: '',
                promocode: '',
                phoneNumber: '',
            },
            isFetching: false,
            errors: [] as string[],
            messages: [] as string[],
            displayLoginOldAcc: false,
        }
    },
    mixins: [ResponsiveDataMixin,MiscMixin],
    async mounted(){
        if(this.isAuthenticated) this.onUserAuthenticated();
        
        const {promocode} = this.$route.query;
        if(promocode){ 
            history.pushState({},'',this.$route.path.split("?")[0]);
            this.$set(this.userData, 'promocode', promocode); 
        }

        const redirResLogin = await checkRedirResTryLogin();
        if(redirResLogin){ 
            await this.processFirebaseGoogleLogin(redirResLogin); 
        }
        await loadExternalScript('https://www.google.com/recaptcha/api.js');
    },
    watch:{
        isAuthenticated(isAuthenticated:boolean){
            if(isAuthenticated) this.onUserAuthenticated();
        },
        newConfirmPass(newVal){
            if(!this.isRegister) return;
            const {password,confirmPassword} = newVal;
            const confirmPasswordInput = this.$refs.confirmPasswordInput as HTMLInputElement;
            if(password!=confirmPassword) confirmPasswordInput.setCustomValidity('Пароли не совпадают')
            else confirmPasswordInput.setCustomValidity('');
        },
    },
    methods: {
        switchDisplayLoginOldAcc() {
            this.displayLoginOldAcc = !this.displayLoginOldAcc
        },
        onUserAuthenticated(){
            // const lastProject = StorageService.loadLastProjectData();
            // if(!lastProject || !lastProject.id){ this.$router.push('/create'); return; }
            // const projectLink = getLinkToProject(lastProject);
            // if(projectLink) createClickAnchor(projectLink);
            // else {
                // console.warn('unable to load previously opened project, project is not published & invitationCode is undefined');
                
                const route = '/projects'
                // const route = (this as any).isMobile ? '/' : '/create'
                this.$router.push(route);
            // }
        },
        firebaseGoogleLogin,
        async processFirebaseGoogleLogin(json:any){
            this.isFetching = true;
            try{
                this.$store.commit(SET_AUTH, json);
                ApiService.setHeader();
                this.addMessage('Авторизация прошла успешно');
            }finally{ 
                this.isFetching = false; 
            }
            //FIXME: Sometimes fetches /me without token, lazy workaround - force reload
            if(this.isAuthenticated) {
                //Yet another lazy workaround for iphones
                this.storePromoCode();
                // if(isMobile()) await new Promise((r)=>setTimeout(r,2000));
                // location.reload();
            }
        },
        async googleLogInFirebase(){
            try {
                const response:any = await firebaseGoogleLogin();
                await this.processFirebaseGoogleLogin(response);
            } catch (error) {
                console.error(error);
                if (!(error+"").includes("The popup has been closed by the user before finalizing the operation."))
                    alert("Во время авторизации произошла ошибка :: "+error+"\nСм. консоль разработчика для подробностей");
            }
        },
        async vkLogIn(){
            const url = API_URL + endpoints.vkPopupAuth;
            if(!isMobile()) {
                const authWindow = window.open(url,'Izibiz: Вконтакте','width=600,height=300') as Window;
                this.isFetching = true;
                while(!authWindow.closed){
                    await new Promise((r)=>setTimeout(r,250));
                    if(jwtService.getToken()){ 
                        this.storePromoCode();
                        try {
                            authWindow.close();
                        } catch (error) {
                            console.error(error)
                        }
                        location.reload();
                    }
                }
                this.isFetching = false;
            }else{
                this.storePromoCode();
                location.href = url;
            }
        },
        storePromoCode(){
            StorageService.getStorageItems().REGISTRATION_PROMO_CODE.setValue(this.userData.promocode);
        },
        switchLoginRegister(){
            if( this.isLogin )
                Vue.nextTick(()=>{
                    Vue.nextTick(()=>{
                        ['email','username','password','confirmPassword','phoneNumber']
                            .forEach((s)=>this.$set(this.userData,s,''));
                    });
                });
            this.$router.push( this.isLogin ? '/register' : '/login' )
            this.$forceUpdate();
        },
        formSubmit(){
            try{this.processInputs()}catch(e){console.error(e); alert(e); return;}
            const {email,password,username,phoneNumber} = this.userData;
            if(this.isRegister) 
                this.register(email,username,password,phoneNumber)
            else
                this.logIn(email,password) 
        },
        async logIn(email:string,password:string){
            // console.log(this.inputValidationMessages)
            // if(this.inputValidationMessages){
            //     alert('Невозможно войти, список ошибок: '+this.inputValidationMessages);
            //     return;
            // }
            try{
                this.isFetching = true;
                await this.$store.dispatch(LOGIN,{email,password,rememberMe:true,captchaResponse:this.captchaResponse});
                this.addMessage('Авторизация прошла успешно');
                setTimeout(()=>{
                    //location.reload(true) 
                    this.$modal.hide('login')
                },2000)
            }catch(e){ 
                if(e.message == 'Request failed with status code 403') this.addError('Неверная пара почта-пароль (Статус ошибки 403)');
                else this.addError(processResponseError(e)); 
            }
            this.isFetching = false;
        },
        async register(email:string,username:string,password:string,phoneNumber:string){
            // console.log(this.inputValidationMessages)
            // if(this.inputValidationMessages){
            //     alert('Невозможно войти, список ошибок: '+this.inputValidationMessages);
            //     return;
            // }
            try{
                this.isFetching = true;
                await this.$store.dispatch(REGISTER,
                    {
                        email,
                        username,
                        password,
                        phoneNumber,
                        captchaResponse:this.captchaResponse
                    });
                this.addMessage(`На почту отправлено письмо с подтверждением. Если письмо не пришло, проверьте папку Спам.`);
                this.storePromoCode();
            }catch(e){ this.addError(processResponseError(e)); }
            this.isFetching = false;
        },
        processInputs(){
            const {emailInput,usernameInput,passwordInput} = this.$refs;
            const requiredFields = (this.isLogin ? [emailInput,passwordInput] : [emailInput,usernameInput,passwordInput]) as HTMLInputElement[];
            const invalidFields = requiredFields.filter((f:HTMLInputElement)=>!f.checkValidity())
            const fieldsAreValid = invalidFields.length == 0;
            if (!this.isLogin && (!usernameInput.value || usernameInput.value.length > 20)) throw new Error("Имя пользователя не может быть больше 20 символов");
            if (!passwordInput.value || passwordInput.value.length > 20) throw new Error("Пароль не может быть больше 20 символов");
            if(!fieldsAreValid) {
                if (emailInput.validationMessage) throw new Error("Неверный формат email: "+usernameInput.validationMessage);
                if (passwordInput.validationMessage) throw new Error("Неверный формат пароля: "+usernameInput.validationMessage);
                throw new Error("Field value(s) are invalid");
            }
        },
        addError(msg:string){
            const errs = this.errors;
            errs.push(msg);
            setTimeout(()=>errs.splice(errs.indexOf(msg),1),messageDisplayDuration);
        },
        addMessage(msg:string){
            const messages = this.messages;
            messages.push(msg);
            setTimeout(()=>messages.splice(messages.indexOf(msg),1),messageDisplayDuration);
        }
    },
    computed: {
        captchaResponse: {
            get():string{
                const {grecaptcha} = (window as any);
                // console.log(grecaptcha)
                if(!grecaptcha || typeof grecaptcha.getResponse != 'function') return '';
                const response = grecaptcha.getResponse();
                grecaptcha.reset();
                return response;
            },
            cache: false,
        },
        phoneNumberRegex: () => phoneNumberRegex,
        captchaSiteKey():string{
            return CAPTCHA_SITE_KEY;
        },
        newConfirmPass():any{
            const {password,confirmPassword} = this.userData;
            return {password,confirmPassword};
        },
        inputValidationMessages: {
            get():string{
                const {emailInput,usernameInput,passwordInput,confirmPasswordInput} = this.$refs as any;
                let arr = [ emailInput, passwordInput ];
                if(this.isRegister) 
            if(this.isRegister) 
                if(this.isRegister) 
                    arr.push(usernameInput, confirmPasswordInput);
                arr = arr.filter((i)=>i)

                const errors = arr
                    .map((i:HTMLInputElement)=>i.validationMessage)
                    .filter((m)=>m)
                    .join('\n');

                return errors;
            },
            cache: false,
        },
        disableLogin: {
            get():boolean{
                // const {emailInput,passwordInput} = this.$refs as any;
                // const arr = [ emailInput, passwordInput ];
                // if(arr.includes(undefined)) return true;

                // const allValid = arr
                //     .map((i)=>i.validity.valid)
                //     .reduce((acc,cur)=>acc&&cur,true);
                return this.isFetching /*|| !allValid*/
            },
            cache: false
        },
        disableRegister: {
            get():boolean{
                // const {emailInput,usernameInput,passwordInput,confirmPasswordInput} = this.$refs as any;
                // const arr = [ emailInput, passwordInput, confirmPasswordInput, ];
                // if(arr.includes(undefined)) return true;

                // const allValid = arr
                //     .map((i)=>i.validity.valid)
                //     .reduce((acc,cur)=>acc&&cur,true);
                return this.isFetching /*|| !allValid*/
            },
            cache: false
        },
        disableTP():boolean{
            return this.isFetching;
        },
        heightInt():number{ return 320 + this.errors.length * 32; },
        messagesAndErrors():{text:string,isError:boolean}[]{ 
            const mapArray = (arr:string[],isError:boolean)=>arr.map((text)=>{return {text,isError}});
            return [ ...mapArray(this.errors,true), ...mapArray(this.messages,false) ] ;
        },
        isLogin: {
            get():boolean{
                return !this.isRegister || this.$route.path.includes( '/login' );
            },
            cache: false
        },
        isRegister: {
            get():boolean{
                return this.$route.path.includes( '/register' );
            },
            cache: false
        },
        ...mapGetters(['isAuthenticated'])
    }
})
